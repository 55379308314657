<i18n>
    [
        "global__viewAll",
    ]
    </i18n>

<template>
    <div v-if="shouldShowProductTray" :style="style">
        <ProductTray
            :id="id"
            :listId="listId"
            :listName="listName"
            :contentModuleId="contentModuleId"
            :contentTitle="contentTitle"
            :products="products"
            :headerLink="trayHeaderLink"
            :headerTitle="headerTitle"
            :headerSubtitle="headerSubtitle"
            :totalProducts="totalProducts"
            :mobilePeek="mobilePeek"
            :mobilePeekPercent="mobilePeekPercent"
            :mediumPeekPercent="mediumPeekPercent"
            :displayCounter="displayCounter"
            :desktopSlideSize="desktopSlideSize"
            :mediumSlideSize="mediumSlideSize"
            :mobileSlideSize="mobileSlideSize"
            :translucentButtons="translucentButtons"
            :lightButtons="lightButtons"
            :lightInfoContainer="lightInfoContainer"
            :catalogResponseId="catalogResponseId"
            :showProductInfo="showProductInfo"
            :filters="recommendationQuery"
            :analyticsType="analyticsType"
            :traceId="traceId"
        />
    </div>
</template>

<script>
import { isEmpty, merge } from 'lodash-es';
import { mapGetters } from 'vuex';

import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

import { getCatalogItems } from '~modules/catalog/js/catalog-api';

import ProductTrayProps from '~coreModules/core/components/mixins/productTrayProps.js';
import ProductTray from '~modules/core/components/ui/ProductTray.vue';

export default {
    name: 'CatalogTray',
    components: {
        ProductTray,
    },
    mixins: [
        ProductTrayProps,
    ],
    props: {
        categorySlug: {
            type: String,
            default: null,
            required: true,
        },
        categoryQuery: {
            type: Object,
            default: null,
        },
        maxProducts: {
            type: Number,
            default: 15,
        },
        shouldShowHeaderLink: {
            type: Boolean,
            default: true,
        },
        lightInfoContainer: {
            type: Boolean,
            default: true,
        },
        paddingBottom: {
            type: String,
            default: null,
        },
        analyticsType: {
            type: String,
            default: null,
        },
        traceId: {
            type: String,
            default: null,
        },
    },
    data() {
        const defaultHeaderLink = {
            linkPath: `/rent/browse/${this.categorySlug}`,
            linkTitle: this.$t('global__viewAll'),
        };

        return {
            products: [],
            totalProducts: this.maxProducts,
            defaultHeaderLink,
            catalogResponseId: null,
        };
    },
    computed: {
        ...mapGetters(SITE_SETTINGS_MODULE_NAME, [
            'catalogFilterWhitelist',
        ]),
        trayHeaderLink() {
            if (!this.shouldShowHeaderLink) {
                return null;
            }

            return this.headerLink || this.defaultHeaderLink;
        },
        recommendationQuery() {
            let query = {
                itemsPerPage: this.maxProducts,
            };

            if (!isEmpty(this.categoryQuery)) {
                query = merge(query, this.categoryQuery);
            }

            return query;
        },
        shouldShowProductTray() {
            return this.products.length >= 5;
        },
        style() {
            if (this.paddingBottom && this.shouldShowProductTray) {
                return {
                    paddingBottom: this.paddingBottom,
                };
            }

            return {};
        },
    },
    async created() {
        if (process.env.VUE_ENV === 'client') {
            await this.getCatalogTrayProducts();
        }
    },
    methods: {
        async getCatalogTrayProducts() {
            try {
                const { products, responseId } = await getCatalogItems({
                    r15Svc: this.$r15Svc,
                    catalogSlug: this.categorySlug,
                    query: this.recommendationQuery,
                    catalogFilterWhitelist: this.catalogFilterWhitelist,
                    logger: this.$logger,
                });

                if (products.length < this.maxProducts) {
                    this.totalProducts = products.length;
                }

                this.catalogResponseId = responseId;
                this.products = products;
            } catch (error) {
                this.$logger.debugError(`Failed to fetch recommendation items for ${this.categorySlug}: `, error);
                Promise.reject(error);
            }
        },
    },
};
</script>
